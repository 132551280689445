require('normalize.css/normalize.css');
require('../scss/index.scss');
// require('./loader.js');

// Import Swiper and modules
// import Swiper, { Navigation, Pagination, Scrollbar, Mousewheel, Lazy, FreeMode } from 'swiper';
// // import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";



import AOS from 'aos';
import 'aos/dist/aos.css';
import { gsap } from 'gsap';


// ============================================================================
// onload + show

let onLoad = []; // array of functions

$(window).on('load', function() {
    AOS.init({once: true});
    $('.template-page').addClass('show');
    $('.section-home').addClass('show');
    // ANIMACION HEADER
    $('.animate').on('click', function(e){
        e.preventDefault();
        let link = $(this).attr('href');
        $('header').addClass('animate');
        $('.image-home').css('opacity', '0');
        $('.template-page').css('opacity', '0');
        $('.template-page').parent().addClass('home-page');
        $('.template-home').parent().removeClass('home-page');
        setTimeout(function(){
            window.location.href = link;
        }, 700, link);
    });

    // load functions
    for (let i = 0; i < onLoad.length; i++) {
        onLoad[i]();
        $('video').each(function( index ) {
            this.play();
            console.log('play');
        });
    }

    // make body visible
    $('body').removeClass('hidden');

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(Draggable);

    let sections = document.querySelectorAll(".desktop-image-home");
    let scrollContainer = document.querySelector("#section-home");
    let scrollBarMobile = document.getElementById("swiper-scrollbar-drag-mobile");

    var totalWidth = 0;

    for (var i = 0; i < sections.length; i++) {
        totalWidth += parseInt(sections[i].offsetWidth, 10);
    }

    $(window).on('resize', function(){
        let sections = document.querySelectorAll(".desktop-image-home");

        for (var i = 0; i < sections.length; i++) {
            totalWidth += parseInt(sections[i].offsetWidth, 10);
        }

       
    });

    let scrollTween = gsap.to(sections, {
        
        x: () =>
            - (totalWidth - (document.documentElement.clientWidth)) +
        "px",
        ease: "none",
        
    });

    let horizontalScroll = ScrollTrigger.create({
        animation: scrollTween,
        trigger: scrollContainer,
        pin: true,
        ease: 'power3.inOut',
        duration: 1,
        scrub: 1,
        end: () => "+=" + (scrollContainer.offsetWidth * 3),
        onUpdate: self => document.getElementById("swiper-scrollbar-drag").style.width = ( self.progress * 100 )+ '%',
    });

    // total scroll amount divided by the total distance that the sections move gives us the ratio we can apply to the pointer movement so that it fits. 
    var dragRatio = scrollContainer.offsetWidth / totalWidth;
    var drag = Draggable.create(".proxy", {
        trigger: scrollContainer,
        type: "x",
        onPress() {
            this.startScroll = horizontalScroll.scroll();
        },
        onDrag() {
            horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * 2);
            // if you don't want it to lag at all while dragging (due to the 1-second scrub), uncomment the next line:
            // horizontalScroll.getTween().progress(1);
        }
    })[0];

    document.onscroll = function(){ 
        var pos = getVerticalScrollPercentage(document.body)

        scrollBarMobile.style.width = pos + '%';


    }
      
    function getVerticalScrollPercentage( elm ){
        var p = elm.parentNode
        return (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight ) * 100
    }


    // $(window).on('resize', function(){

    //     Draggable.create("#section-home",{
    //         inertia: true,
    //         type: 'x',
    //     });
    //     gsap.to(container, {
    //         x: () =>
    //         - (container.scrollWidth - document.documentElement.clientWidth) +
    //         "px",
           
    //         ease: "power2.out",
    //         scrollTrigger: {
    //             trigger: container,
    //             invalidateOnRefresh: true,
    //             pin: true,
    //             scrub: 1,
    //             end: () => "+=" + container.offsetWidth
    //         },
    //     });
    // });

    // gsap.to(container, {
    //     x: () =>
    //     - (container.scrollWidth - document.documentElement.clientWidth) +
    //     "px",
       
    //     ease: "power2.out",
    //     scrollTrigger: {
    //         trigger: container,
    //         invalidateOnRefresh: true,
    //         pin: true,
    //         scrub: 1,
    //         end: () => "+=" + container.offsetWidth
    //     },
    // });


    // // SWIPER + VIDEO PLAY
    // Swiper.use([Navigation, Lazy, Mousewheel, Scrollbar, FreeMode]);

    // const swiper = new Swiper('.swiper', {
    //     // Optional parameters
    //     direction: 'vertical',
    //     slidesPerView: 'auto',
    //     spaceBetween: 0,
    //     observer: true, 
    //     observeParents: true,        
    //     watchSlidesProgress: true,
    //     // Enable lazy loading
    //     allowTouchMove: true,
    //     lazy: false,
    //     freeMode: true,
    //     keyboardControl: true,
    //     mousewheelControl: true,
    //     mousewheelForceToAxis: true,
    //     mousewheelReleaseOnEdges: true,
    //     freeMode: {
    //         enabled: true,
    //         sticky: false,
    //         momentumBounce: false,
    //     },
    //     freeModeSticky: false,
    //     mousewheel: {
    //         enabled: true,
    //         sensitivity: 5.5,
    //       },
        
    //     // Navigation arrows
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
        
    //     // And if we need scrollbar
    //     scrollbar: {
    //         el: '.swiper-scrollbar',
    //     },
    //     scrollbarDraggable: false,
    //     scrollbarHide: false,

    //     on: {
    //         init: function () {
    //             console.log('swiper initialized');
    //             var currentVideo =  $(".swiper-slide-active").find("video");
    //             currentVideo.each(function( index ) {
    //                 this.play();
    //                 console.log('play');
    //             });

                
    //         },
            
    //         // slideChange: function() {
    //         //     setTimeout(function () {
    //         //         swiper.params.mousewheel.releaseOnEdges = false;
    //         //     }, 500);
    //         // },
    //         // reachEnd: function() {
    //         //     setTimeout(function () {
    //         //         swiper.params.mousewheel.releaseOnEdges = true;
    //         //     }, 750);
    //         // }
            
    //     },

    //     breakpoints: {
    //         // when window width is >= 1024px
    //         1025: {
    //             direction: 'horizontal',  
                     
    //         },
            
    //     },
    // });

    // swiper.scrollbar.setTranslate(function(){
    //     $('.swiper-scrollbar-drag').css('transform', 'translate3d(0,0,0)');
        
    // });

    // /* SWIPER API - Event will be fired after animation to other slide (next or previous) */
    // swiper.on('slideChange', function () {
    //     console.log('slide changed');        

    //     /* SWIPER GET CURRENT AND PREV SLIDE (AND VIDEO INSIDE) */
    //     var prevVideo =  $(".swiper-slide-prev").find("video");
    //     var nextVideo =  $(".swiper-slide-next").find("video");
    //     var currentVideo =  $(".swiper-slide-visible").find("video");
    //     currentVideo.each(function( index ) {
    //         this.play();
    //         console.log('play');
    //     });
    // });



    // var $scrollbarDrag = $('.swiper-scrollbar-2 .swiper-scrollbar-drag');

    // var _carouselTranslateScrollbar = function(swiper, progress) {
    //     var increaseRatio = 2,
    //     increasedProgress = progress * increaseRatio;
    //     var totalWidth = (increasedProgress * 100) / 2;
        
    //     // @TODO sort selector
    //     $('.swiper-scrollbar-2 .swiper-scrollbar-drag').css({
    //         'background-color': '#fff',
    //         'width': totalWidth + '%',
    //     });
    // };

    // var _carouselSetTransitions = function(swiper, transition) {
    //     $scrollbarDrag.css('transition-duration', transition + 'ms');
    // };

    // swiper.on('progress', _carouselTranslateScrollbar);
    // swiper.on('setTransition', _carouselSetTransitions);




});


// HELPERS

// function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
//     var mql = window.matchMedia(mediaQuery);
//     mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
//     layoutChangedCallback(mql.matches);
// }



$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};



// var bordersVertical = $('.border-vertical');
    // var bordersHorizontal = $('.border-horizontal');

    // // SCROLL
    // // $(window).on('resize scroll', function() { 
        
    // //     bordersVertical.each(function(i){
    // //         if ($(this).isInViewport()) {
    // //             // do something
    // //             $(this).addClass('activate'); 
    // //             console.log($(this).isInViewport()); 
    // //         }
    // //     });

    // //     bordersHorizontal.each(function(i){
    // //         if ($(this).isInViewport()) {
    // //             // do something
    // //             $(this).addClass('activate'); 
    // //             console.log($(this).isInViewport());  
    // //         }
    // //     });
    
    // // });

    // // bordersVertical.each(function(i){
    // //     if ($(this).isInViewport()) {
    // //         // do something
    // //         $(this).addClass('activate'); 
    // //         console.log($(this).isInViewport()); 
    // //     }
    // // });

    // // bordersHorizontal.each(function(i){
    // //     if ($(this).isInViewport()) {
    // //         // do something
    // //         $(this).addClass('activate'); 
    // //         console.log($(this).isInViewport());  
    // //     }
    // // });

    // Animación home images
    // setTimeout(function(){
    //     var elements = $('.image-home');
    //     elements.each(function(i){
    //         gsap.fromTo($(this),{x: -2000}, {duration: i+1, x: 0});
    //     });
        
    // }, 500);

    // window.addEventListener('mousewheel',handleMouseWheel);
    //     function handleMouseWheel(e){
    //     var translate = { y: swiper.translate }
    //     TweenMax.to(translate,1.2, { y: (e.deltaY) * -1, onUpdate:function(){
    //         swiper.setTranslate(translate.y)
    //     } })
    //     gsap.fromTo($('.section-home'),{x: (e.deltaY) * -1}, {duration: 1.2});
    // }